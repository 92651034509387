import React from 'react';
import RootCauseAnalysisPro from './components/RootCauseAnalysisPro';

function App() {
  return (
    <div className="App">
      <RootCauseAnalysisPro />
    </div>
  );
}

export default App;