import React, { useState } from 'react';

const CorrectiveActionRecord = () => {
  const [car, setCAR] = useState({
    findingDescription: '',
    rootCause: '',
    correctiveAction: '',
    responsiblePerson: '',
    targetDate: '',
    status: 'Open'
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCAR(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Submitted CAR:', car);
    // Here you would typically send this data to a backend or store it in a parent component
  };

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold">Corrective Action Record (CAR)</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="findingDescription" className="block mb-1">Finding Description:</label>
          <textarea
            id="findingDescription"
            name="findingDescription"
            value={car.findingDescription}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
            rows="3"
          />
        </div>
        <div>
          <label htmlFor="rootCause" className="block mb-1">Root Cause:</label>
          <input
            type="text"
            id="rootCause"
            name="rootCause"
            value={car.rootCause}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
          />
        </div>
        <div>
          <label htmlFor="correctiveAction" className="block mb-1">Corrective Action:</label>
          <textarea
            id="correctiveAction"
            name="correctiveAction"
            value={car.correctiveAction}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
            rows="3"
          />
        </div>
        <div>
          <label htmlFor="responsiblePerson" className="block mb-1">Responsible Person:</label>
          <input
            type="text"
            id="responsiblePerson"
            name="responsiblePerson"
            value={car.responsiblePerson}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
          />
        </div>
        <div>
          <label htmlFor="targetDate" className="block mb-1">Target Date:</label>
          <input
            type="date"
            id="targetDate"
            name="targetDate"
            value={car.targetDate}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
          />
        </div>
        <div>
          <label htmlFor="status" className="block mb-1">Status:</label>
          <select
            id="status"
            name="status"
            value={car.status}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
          >
            <option value="Open">Open</option>
            <option value="In Progress">In Progress</option>
            <option value="Closed">Closed</option>
          </select>
        </div>
        <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
          Submit CAR
        </button>
      </form>
    </div>
  );
};

export default CorrectiveActionRecord;