const rootCauseData = {
    "query": [
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Resource Management",
            "Code": "RM01",
            "Sub-category": "Insufficient human resources"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Resource Management",
            "Code": "RM011",
            "Sub-category": "·         staffing, manning for task"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Resource Management",
            "Code": "RM02",
            "Sub-category": "Insufficient/ defective equipment/facilities available"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Resource Management",
            "Code": "RM021",
            "Sub-category": "·         improper infrastructure (equipment , facilities, material, technology support, intelligence)"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Resource Management",
            "Code": "RM022",
            "Sub-category": "·         inadequate procurement & acquisition process"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Resource Management",
            "Code": "RM023",
            "Sub-category": "·         inadequate attrition or disposal policies"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Climate",
            "Code": "RM03",
            "Sub-category": "Insufficient financial/budget resources, excessive cost cutting"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Climate",
            "Code": "RM04",
            "Sub-category": "Insufficient selection process / qualification"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Climate",
            "Code": "RM041",
            "Sub-category": "·         long selection process"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Climate",
            "Code": "RM042",
            "Sub-category": "·         people with improper qualification selected"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Climate",
            "Code": "RM043",
            "Sub-category": "·         unavailable training "
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Climate",
            "Code": "RM05",
            "Sub-category": "Poor planning, prioritization"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Climate",
            "Code": "RM051",
            "Sub-category": "·         conflicting or inadequate prioritization "
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Climate",
            "Code": "RM052",
            "Sub-category": "·         conflicting goals"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Climate",
            "Code": "RM053",
            "Sub-category": "·         inadequate policies impacting planning or  availability of resources"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Climate",
            "Code": "RM06",
            "Sub-category": "Other (description to be provided by Auditee)"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OC01",
            "Sub-category": "Inadequate company culture"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OC011",
            "Sub-category": "·         inadequate norms, values, beliefs, policies"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OC012",
            "Sub-category": "·         tolerance to drugs and alcohol"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OC013",
            "Sub-category": "·         tolerance to abundance or deviation from the rules  "
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OC02",
            "Sub-category": "Inadequate HR policies"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OC021",
            "Sub-category": "·         inadequate incentive system affecting motivation"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OC022",
            "Sub-category": "·         unstable workforce (hiring, retention) "
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OC023",
            "Sub-category": "·         pressure on employees (evaluations, promotions), industrial, union , commercial,  peer pressure"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OC03",
            "Sub-category": "Inadequate organizational structure"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OC031",
            "Sub-category": "·         unclear lines of reporting"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OC032",
            "Sub-category": "·         inadequate delegation of authority"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OC033",
            "Sub-category": "·         lack of formal accountabilities for actions"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OC034",
            "Sub-category": "·         inadequate accessibility or visibility of supervisor"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OC035",
            "Sub-category": "·         inappropriate organizational changes (personnel/aircraft/equipment)  impacting the carrying out of duties"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OC04",
            "Sub-category": "Other (description to be provided by Auditee)"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OP01",
            "Sub-category": "Inadequate oversight resources"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OP011",
            "Sub-category": "·         management without sufficient support, planning or oversight"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OP012",
            "Sub-category": "·         inadequate risk management, risk assessment "
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OP013",
            "Sub-category": "·         insufficient safety programs"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OP014",
            "Sub-category": "·         operations,  philosophy is flawed or accepts unnecessary risk"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OP015",
            "Sub-category": "·         insufficient company or contracted training programs"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OP02",
            "Sub-category": "Unclear/unavailable/inadequate regulations, standard procedures"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OP021",
            "Sub-category": "·         standards, objectives, procedure, instruction, guidance, information"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OP022",
            "Sub-category": "·         revision process "
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OP023",
            "Sub-category": "·         documentation "
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OP024",
            "Sub-category": "·         communication of changes"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OP03",
            "Sub-category": "Inadequate operations"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OP031",
            "Sub-category": "·         high operational tempo, quotas and time pressure"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OP032",
            "Sub-category": "·         workload (additional duties and off-duty activities)"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OP033",
            "Sub-category": "·         KPRs and measurement system"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OP034",
            "Sub-category": "·         schedules, deficient planning"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OP04",
            "Sub-category": "Inadequate training"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OP041",
            "Sub-category": "·         provided training was not sufficient"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OP042",
            "Sub-category": "·         training was not focus on job duties"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OP043",
            "Sub-category": "·         training is not standardized"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OP044",
            "Sub-category": "·         there is only OJT provided"
        },
        {
            "Investigation Title": "ORGANIZATIONAL INFLLUENCES",
            "Category": "Organizational Processes",
            "Code": "OP05",
            "Sub-category": "Other (description to be provided by Auditee)"
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Inadequate Supervision",
            "Code": "IS01",
            "Sub-category": "Failed to provide leadership and guidance "
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Inadequate Supervision",
            "Code": "IS011",
            "Sub-category": "·         not providing policy,  instruction, information"
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Inadequate Supervision",
            "Code": "IS012",
            "Sub-category": "·         inadequate task delegation/prioritization"
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Inadequate Supervision",
            "Code": "IS013",
            "Sub-category": "·         personality conflict with team member "
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Inadequate Supervision",
            "Code": "IS014",
            "Sub-category": "·         lack of feedback"
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Inadequate Supervision",
            "Code": "IS02",
            "Sub-category": "Failed to track performance"
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Inadequate Supervision",
            "Code": "IS021",
            "Sub-category": "·         task allocation "
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Inadequate Supervision",
            "Code": "IS022",
            "Sub-category": "·         task achievements"
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Inadequate Supervision",
            "Code": "IS023",
            "Sub-category": "·         adequate rest period"
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Inadequate Supervision",
            "Code": "IS03",
            "Sub-category": "Failed to track qualification"
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Inadequate Supervision",
            "Code": "IS031",
            "Sub-category": "·         no tracking system in place"
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Inadequate Supervision",
            "Code": "IS032",
            "Sub-category": "·         records availability and control"
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Inadequate Supervision",
            "Code": "IS04",
            "Sub-category": "Failed to provide/ensure adequate training"
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Inadequate Supervision",
            "Code": "IS041",
            "Sub-category": "·         no training program established"
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Inadequate Supervision",
            "Code": "IS042",
            "Sub-category": "·         no training scheduled"
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Inadequate Supervision",
            "Code": "IS043",
            "Sub-category": "·         no tracking system in place"
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Inadequate Supervision",
            "Code": "IS044",
            "Sub-category": "·         training not scheduled on time"
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Inadequate Supervision",
            "Code": "IS045",
            "Sub-category": "·         records availability"
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Inadequate Supervision",
            "Code": "IS05",
            "Sub-category": "Other (description to be provided by Auditee)"
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Planned Inappropriate Activities",
            "Code": "PA01",
            "Sub-category": "Inappropriate employee scheduling / assigning/ manning "
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Planned Inappropriate Activities",
            "Code": "PA011",
            "Sub-category": "·         excessive workload over-tasking"
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Planned Inappropriate Activities",
            "Code": "PA012",
            "Sub-category": "·         inadequate personnel "
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Planned Inappropriate Activities",
            "Code": "PA013",
            "Sub-category": "·         resources with Limited Recent Experience (not authorized to perform particular task)"
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Planned Inappropriate Activities",
            "Code": "PA014",
            "Sub-category": "·         resources with Limited Total Experience (insufficient experience to perform the task)"
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Planned Inappropriate Activities",
            "Code": "PA015",
            "Sub-category": "·         resources not proficient to perform task"
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Planned Inappropriate Activities",
            "Code": "PA02",
            "Sub-category": "Inadequate Risk Assessment"
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Planned Inappropriate Activities",
            "Code": "PA021",
            "Sub-category": "·         Lack of evaluation of the risks associated with a task  "
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Planned Inappropriate Activities",
            "Code": "PA03",
            "Sub-category": "Authorization to take unnecessary risks"
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Planned Inappropriate Activities",
            "Code": "PA04",
            "Sub-category": "Other (description to be provided by Auditee)"
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Failed to Correct a Known Problem",
            "Code": "FP01",
            "Sub-category": "Supervisor failed to identify and correct inappropriate behavior or unsafe tendencies "
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Failed to Correct a Known Problem",
            "Code": "FP02",
            "Sub-category": "Supervisor failed to correct known hazard/problem/error/inefficiency"
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Failed to Correct a Known Problem",
            "Code": "FP03",
            "Sub-category": "Supervisor failed to report a hazard or unsafe tendencies"
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Failed to Correct a Known Problem",
            "Code": "FP04",
            "Sub-category": "Other (description to be provided by Auditee)"
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Supervisory Violations",
            "Code": "SV01",
            "Sub-category": "Supervisor failed to enforce rules and regulations "
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Supervisory Violations",
            "Code": "SV011",
            "Sub-category": "Supervisor directed / authorized subordinates to violate existing rules"
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Supervisory Violations",
            "Code": "SV02",
            "Sub-category": "·         supervisor tolerated  unwritten or unofficial (“ De facto”) policy followed by individuals"
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Supervisory Violations",
            "Code": "SV021",
            "Sub-category": "Supervisor authorized unqualified person for work"
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Supervisory Violations",
            "Code": "SV03",
            "Sub-category": "·         falsified documents / records"
        },
        {
            "Investigation Title": "UNSAFE     SUPERVISON",
            "Category": "Supervisory Violations",
            "Code": "SV04",
            "Sub-category": "Other (description to be provided by Auditee)"
        },
        {
            "Investigation Title": "PRECONDITIONS FOR UNSAFE ACTS",
            "Category": "Physical Environment",
            "Code": "PN01",
            "Sub-category": "Restricted visibility, altitude, terrain, weather conditions "
        },
        {
            "Investigation Title": "PRECONDITIONS FOR UNSAFE ACTS",
            "Category": "Physical Environment",
            "Code": "PN02",
            "Sub-category": "Inadequate lighting, noise, vibration"
        },
        {
            "Investigation Title": "PRECONDITIONS FOR UNSAFE ACTS",
            "Category": "Physical Environment",
            "Code": "PN03",
            "Sub-category": "Inadequate cleanliness, surface conditions"
        },
        {
            "Investigation Title": "PRECONDITIONS FOR UNSAFE ACTS",
            "Category": "Physical Environment",
            "Code": "PN04",
            "Sub-category": "Inadequate facilities/walk/road layout, signing, marking"
        },
        {
            "Investigation Title": "PRECONDITIONS FOR UNSAFE ACTS",
            "Category": "Physical Environment",
            "Code": "PN05",
            "Sub-category": "Other (description to be provided by Auditee)"
        },
        {
            "Investigation Title": "PRECONDITIONS FOR UNSAFE ACTS",
            "Category": "Technological Environment",
            "Code": "TN01",
            "Sub-category": "Inappropriate/poor design of equipment, tool, parts, material "
        },
        {
            "Investigation Title": "PRECONDITIONS FOR UNSAFE ACTS",
            "Category": "Technological Environment",
            "Code": "TN02",
            "Sub-category": "Inappropriate automation, function, reliability"
        },
        {
            "Investigation Title": "PRECONDITIONS FOR UNSAFE ACTS",
            "Category": "Technological Environment",
            "Code": "TN03",
            "Sub-category": "Inappropriate interface design"
        },
        {
            "Investigation Title": "PRECONDITIONS FOR UNSAFE ACTS",
            "Category": "Technological Environment",
            "Code": "TN04",
            "Sub-category": "Inappropriate communications system"
        },
        {
            "Investigation Title": "PRECONDITIONS FOR UNSAFE ACTS",
            "Category": "Technological Environment",
            "Code": "TN05",
            "Sub-category": "Other (description to be provided by Auditee)"
        },
        {
            "Investigation Title": "PRECONDITIONS FOR UNSAFE ACTS",
            "Category": "Psychological  and Physical Conditions",
            "Code": "PC01",
            "Sub-category": "Inattention, apathy, complacency, boredom, distraction, stress, exhaustion (Burnout) "
        },
        {
            "Investigation Title": "PRECONDITIONS FOR UNSAFE ACTS",
            "Category": "Psychological and Physical Conditions",
            "Code": "PC02",
            "Sub-category": "Channelized attention and actions, confusion, disorientation"
        },
        {
            "Investigation Title": "PRECONDITIONS FOR UNSAFE ACTS",
            "Category": "Psychological and Physical Conditions",
            "Code": "PC03",
            "Sub-category": "Personality style"
        },
        {
            "Investigation Title": "PRECONDITIONS FOR UNSAFE ACTS",
            "Category": "Psychological  and Physical Conditions",
            "Code": "PC04",
            "Sub-category": "Illness, sickness"
        },
        {
            "Investigation Title": "PRECONDITIONS FOR UNSAFE ACTS",
            "Category": "Psychological and Physical Conditions",
            "Code": "PC05",
            "Sub-category": "Effects of alcohol, drugs (before or while on duty)"
        },
        {
            "Investigation Title": "PRECONDITIONS FOR UNSAFE ACTS",
            "Category": "Psychological and Physical Conditions",
            "Code": "PC06",
            "Sub-category": "Inadequate experience for situation, insufficient reaction time"
        },
        {
            "Investigation Title": "PRECONDITIONS FOR UNSAFE ACTS",
            "Category": "Psychological and Physical Conditions",
            "Code": "PC07",
            "Sub-category": "Misperception of operational conditions , incorrect interpretation and understanding"
        },
        {
            "Investigation Title": "PRECONDITIONS FOR UNSAFE ACTS",
            "Category": "Psychological and Physical Conditions",
            "Code": "PC08",
            "Sub-category": "Other (description to be provided by Auditee)"
        },
        {
            "Investigation Title": "PRECONDITIONS FOR UNSAFE ACTS",
            "Category": "Personal Readiness",
            "Code": "PR01",
            "Sub-category": "Inadequate Rest "
        },
        {
            "Investigation Title": "PRECONDITIONS FOR UNSAFE ACTS",
            "Category": "Personal Readiness",
            "Code": "PR02",
            "Sub-category": "Inadequate physical fitness, Insufficient diet, nutrition"
        },
        {
            "Investigation Title": "PRECONDITIONS FOR UNSAFE ACTS",
            "Category": "Personal Readiness",
            "Code": "PR03",
            "Sub-category": "Self-medication and , unreported medical conditions"
        },
        {
            "Investigation Title": "PRECONDITIONS FOR UNSAFE ACTS",
            "Category": "Personal Readiness",
            "Code": "PR04",
            "Sub-category": "Inadequate personal preparation"
        },
        {
            "Investigation Title": "PRECONDITIONS FOR UNSAFE ACTS",
            "Category": "Personal Readiness",
            "Code": "PR05",
            "Sub-category": "Other (description to be provided by Auditee)"
        },
        {
            "Investigation Title": "PRECONDITIONS FOR UNSAFE ACTS",
            "Category": "CRM",
            "Code": "CM01",
            "Sub-category": "Lack of assertiveness or leadership  "
        },
        {
            "Investigation Title": "PRECONDITIONS FOR UNSAFE ACTS",
            "Category": "CRM",
            "Code": "CM02",
            "Sub-category": "Lack of planning or preparation, inadequate briefing, insufficient re-planning"
        },
        {
            "Investigation Title": "PRECONDITIONS FOR UNSAFE ACTS",
            "Category": "CRM",
            "Code": "CM03",
            "Sub-category": "Poor workload management or task delegation"
        },
        {
            "Investigation Title": "PRECONDITIONS FOR UNSAFE ACTS",
            "Category": "CRM",
            "Code": "CM04",
            "Sub-category": "Authority gradient,  poor teamwork"
        },
        {
            "Investigation Title": "PRECONDITIONS FOR UNSAFE ACTS",
            "Category": "CRM",
            "Code": "CM05",
            "Sub-category": "Lack of cross-monitoring performance, supportive feedback or acknowledgement"
        },
        {
            "Investigation Title": "PRECONDITION FOR UNSAFE ACTS",
            "Code": "CM06",
            "Sub-category": "Poor communication of critical information  and poor decision making"
        },
        {
            "Investigation Title": "PRECONDITIONS FOR UNSAFE ACTS",
            "Code": "CM07",
            "Sub-category": "Other (description to be provided by Auditee)"
        },
        {
            "Investigation Title": "UNSAFE ACTS",
            "Category": "Decision Errors",
            "Code": "DE01",
            "Sub-category": "Inadequate risk evaluation during operation ,misjudging "
        },
        {
            "Investigation Title": "UNSAFE ACTS",
            "Code": "DE02",
            "Sub-category": "Ignored caution, warning"
        },
        {
            "Investigation Title": "UNSAFE ACTS",
            "Code": "DE03",
            "Sub-category": "Task misprioritization  "
        },
        {
            "Investigation Title": "UNSAFE ACTS",
            "Code": "DE04",
            "Sub-category": "Other (description to be provided by Auditee)"
        },
        {
            "Investigation Title": "UNSAFE ACTS",
            "Category": "Skill Based Errors",
            "Code": "SE01",
            "Sub-category": "Incorrect operation/handling of equipment / inappropriate use of automation "
        },
        {
            "Investigation Title": "UNSAFE ACTS",
            "Code": "SE02",
            "Sub-category": "Incorrect operations / handling equipment"
        },
        {
            "Investigation Title": "UNSAFE ACTS",
            "Code": "SE03",
            "Sub-category": "Inadvertently activating or deactivating equipment, controls or switches"
        },
        {
            "Investigation Title": "UNSAFE ACTS",
            "Code": "SE04",
            "Sub-category": "Failure to see and react / fail"
        },
        {
            "Investigation Title": "UNSAFE ACTS",
            "Code": "SE05",
            "Sub-category": "Other (description to be provided by Auditee)"
        },
        {
            "Investigation Title": "UNSAFE ACTS",
            "Category": "Perception Errors",
            "Code": "PE01",
            "Sub-category": "Error due to misperception, illusion, disorientation, misjudgement"
        },
        {
            "Investigation Title": "UNSAFE ACTS",
            "Code": "PE02",
            "Sub-category": "Spatial disorientation, vertigo, visual illusion"
        },
        {
            "Investigation Title": "UNSAFE ACTS",
            "Code": "PE03",
            "Sub-category": "Other (description to be provided by Auditee)"
        },
        {
            "Investigation Title": "UNSAFE ACTS",
            "Category": "Exceptional violations ",
            "Code": "EV01",
            "Sub-category": "Lack of discipline  "
        },
        {
            "Investigation Title": "UNSAFE ACTS",
            "Code": "EV02",
            "Sub-category": "Rules, regulations, procedures not followed"
        },
        {
            "Investigation Title": "UNSAFE ACTS",
            "Code": "EV03",
            "Sub-category": "Intentional bending the rules, procedures, policies by individual or team without cause or need"
        },
        {
            "Investigation Title": "UNSAFE ACTS",
            "Code": "EV04",
            "Sub-category": "Others (description to be provided by Auditee)"
        },
        {
            "Investigation Title": "UNSAFE ACTS",
            "Category": "Routine violations",
            "Code": "RV01",
            "Sub-category": "Widespread, routine,  systemic , habitual violation by individual or team  "
        },
        {
            "Investigation Title": "UNSAFE ACTS",
            "Code": "RV02",
            "Sub-category": "Violation based on Risk Assessment"
        },
        {
            "Investigation Title": "UNSAFE ACTS",
            "Code": "RV03",
            "Sub-category": "Other (description to be provided by Auditee)"
        }
    ]
};
export default rootCauseData;