import React, { useState, useEffect, useMemo } from 'react';
import { ArrowRight, RefreshCw, Save, PlusCircle, XCircle, Edit2 } from 'lucide-react';

const FishboneDiagramTool = () => {
  const predefinedCategories = {
    '4M': ['Methods', 'Materials', 'Machinery', 'People'],
    '4P': ['Policies', 'Procedures', 'People', 'Plant'],
    '6M': ['Machine', 'Method', 'Materials', 'Measurement', 'Man', 'Mother Nature'],
    'Other': ['Service', 'Quality', 'Technology', 'Consumables', 'Work Processes', 'Environment', 'Service Level']
  };

  const [problem, setProblem] = useState('');
  const [conclusion, setConclusion] = useState('');
  const [savedContent, setSavedContent] = useState('');
  const [causeClassifications, setCauseClassifications] = useState({
    root: [],
    contributing: [],
    possible: []
  });
  const [selectedPreset, setSelectedPreset] = useState('4M');
  const [categories, setCategories] = useState(predefinedCategories['4M']);
  const [causes, setCauses] = useState({});
  const [newCategory, setNewCategory] = useState('');
  const [editingCause, setEditingCause] = useState(null);
  const [editValue, setEditValue] = useState('');

  useEffect(() => {
    const initialCauses = {};
    categories.forEach(category => {
      initialCauses[category] = [];
    });
    setCauses(initialCauses);
  }, [categories]);

  const allCauses = useMemo(() => Object.values(causes).flat(), [causes]);

  const addCause = (category, cause) => {
    if (cause.trim() !== '') {
      setCauses(prevCauses => ({
        ...prevCauses,
        [category]: [...(prevCauses[category] || []), cause]
      }));
    }
  };

  const removeCause = (category, index) => {
    setCauses(prevCauses => ({
      ...prevCauses,
      [category]: prevCauses[category].filter((_, i) => i !== index)
    }));

    setCauseClassifications(prev => {
      const causeToRemove = causes[category][index];
      return {
        root: prev.root.filter(c => c !== causeToRemove),
        contributing: prev.contributing.filter(c => c !== causeToRemove),
        possible: prev.possible.filter(c => c !== causeToRemove)
      };
    });
  };

  const addCategory = () => {
    if (newCategory.trim() !== '' && !categories.includes(newCategory)) {
      setCategories(prev => [...prev, newCategory]);
      setCauses(prev => ({ ...prev, [newCategory]: [] }));
      setNewCategory('');
    }
  };

  const removeCategory = (category) => {
    setCategories(prev => prev.filter(c => c !== category));
    setCauses(prev => {
      const { [category]: _, ...rest } = prev;
      return rest;
    });
  };

  const handlePresetChange = (preset) => {
    setSelectedPreset(preset);
    setCategories(predefinedCategories[preset]);
  };

  const refreshDiagram = () => {
    setProblem('');
    setConclusion('');
    setSavedContent('');
    setCategories(predefinedCategories['4M']);
    setCauses({});
    setSelectedPreset('4M');
    setCauseClassifications({ root: [], contributing: [], possible: [] });
  };

  const saveDiagram = () => {
    const content = `
Problem Statement: ${problem}

Categories:
${Object.entries(causes).map(([category, categoryCauses]) => `
${category}:
${categoryCauses.map(cause => `- ${cause}`).join('\n')}
`).join('\n')}

Root Cause(s):
${causeClassifications.root.join('\n')}

Contributing Cause(s):
${causeClassifications.contributing.join('\n')}

Possible Cause(s):
${causeClassifications.possible.join('\n')}

Conclusion:
${conclusion}
    `.trim();

    setSavedContent(content);
  };

  const renderFishboneDiagram = () => {
    const svgWidth = '100%';
    const svgHeight = '600px';
    const centerY = 300;
    const spineLength = 800;
    const categorySpacing = spineLength / (categories.length + 1);
    const problemBoxWidth = 300;
    const problemBoxHeight = 100;
    const causeBoxWidth = 300;
    const causeBoxHeight = 150;

    return (
      <svg width={svgWidth} height={svgHeight} viewBox="0 0 1200 600" style={{ border: '1px solid black' }}>
        <line x1="50" y1={centerY} x2={1200 - 50} y2={centerY} stroke="black" strokeWidth="2" />

     {/* Problem Statement Box */}
     <rect x={1200 - problemBoxWidth - 50} y={centerY - problemBoxHeight / 2} width={problemBoxWidth} height={problemBoxHeight} fill="white" stroke="black" />
        <foreignObject x={1200 - problemBoxWidth - 45} y={centerY - problemBoxHeight / 2 + 5} width={problemBoxWidth - 10} height={problemBoxHeight - 10}>
          <div className="text-center">
            <p className="font-bold">Problem Statement:</p>
            <p>{problem || 'Enter problem'}</p>
          </div>
        </foreignObject>

        {/* Cause Classifications */}
        <foreignObject x={1200 - causeBoxWidth - 50} y={centerY + problemBoxHeight / 2 + 10} width={causeBoxWidth} height={causeBoxHeight}>
          <div className="text-sm">
            <p><strong>Root Cause(s):</strong> {causeClassifications.root.join(', ')}</p>
            <p><strong>Contributing Cause(s):</strong> {causeClassifications.contributing.join(', ')}</p>
            <p><strong>Possible Cause(s):</strong> {causeClassifications.possible.join(', ')}</p>
          </div>
        </foreignObject>

        {categories.map((category, index) => {
          const x = 50 + (index + 1) * categorySpacing;
          const isUpper = index % 2 === 0;
          const yDirection = isUpper ? -1 : 1;
          return (
            <g key={category}>
              <line
                x1={x}
                y1={centerY}
                x2={x}
                y2={centerY + yDirection * 200}
                stroke="black"
                strokeWidth="2"
              />
              <text
                x={x}
                y={centerY + yDirection * 220}
                textAnchor="middle"
                dominantBaseline={isUpper ? "auto" : "hanging"}
                fill="blue"
                fontWeight="bold"
              >
                {category}
              </text>
              {causes[category] && causes[category].map((cause, causeIndex) => (
                <text
                  key={causeIndex}
                  x={x + (isUpper ? 5 : -5)}
                  y={centerY + yDirection * (20 + causeIndex * 20)}
                  textAnchor={isUpper ? "start" : "end"}
                  dominantBaseline="middle"
                  fontSize="12"
                  fill="red"
                >
                  {cause}
                </text>
              ))}
            </g>
          );
        })}
      </svg>
    );
  };

  const classifyCause = (cause, newClassification) => {
    if (cause) {
      setCauseClassifications(prev => {
        const updatedClassifications = {
          root: prev.root.filter(c => c !== cause),
          contributing: prev.contributing.filter(c => c !== cause),
          possible: prev.possible.filter(c => c !== cause)
        };
        if (newClassification && newClassification !== "") {
          updatedClassifications[newClassification] = [...updatedClassifications[newClassification], cause];
        }
        return updatedClassifications;
      });
    }
  };

  const getCauseClassification = (cause) => {
    if (causeClassifications.root.includes(cause)) return 'root';
    if (causeClassifications.contributing.includes(cause)) return 'contributing';
    if (causeClassifications.possible.includes(cause)) return 'possible';
    return '';
  };

  const startEditing = (category, index) => {
    setEditingCause({ category, index });
    setEditValue(causes[category][index]);
  };

  const saveEdit = () => {
    if (editingCause && editValue.trim() !== '') {
      setCauses(prevCauses => ({
        ...prevCauses,
        [editingCause.category]: prevCauses[editingCause.category].map(
          (cause, index) => index === editingCause.index ? editValue.trim() : cause
        )
      }));
      setEditingCause(null);
      setEditValue('');
    }
  };

  const cancelEdit = () => {
    setEditingCause(null);
    setEditValue('');
  };

  return (
    <div className="p-4 w-full">
      <h1 className="text-2xl font-bold mb-4">Fishbone Diagram Tool</h1>
  
      {/* Problem Statement Section */}
      <div className="mb-4 w-full">
        <label htmlFor="problemStatement" className="block text-2xl font-bold mb-2">Problem Statement:</label>
        <textarea
          id="problemStatement"
          value={problem}
          onChange={(e) => setProblem(e.target.value)}
          placeholder="Enter problem statement"
          className="w-full p-2 border rounded h-32"
          aria-label="Problem Statement"
        />
      </div>
  
      {/* Select Causal Areas Section */}
      <div className="mb-4 w-full">
        <h2 className="text-xl font-bold">Select Causal Areas:</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2 w-full">
          <select
            value={selectedPreset}
            onChange={(e) => handlePresetChange(e.target.value)}
            className="p-2 border rounded w-full"
            aria-label="Select Preset"
          >
            {Object.keys(predefinedCategories).map(preset => (
              <option key={preset} value={preset}>{preset}</option>
            ))}
          </select>
          <input
            type="text"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            placeholder="Add custom category"
            className="p-2 border rounded w-full"
            aria-label="New Category"
          />
          <button
            onClick={addCategory}
            className="p-2 bg-blue-500 text-white rounded hover:bg-blue-600 active:bg-blue-700 transition-colors w-full"
            aria-label="Add Category"
          >
            <PlusCircle size={16} />
          </button>
        </div>
      </div>
  
      {/* Add Causes Section */}
      <div className="mb-4 w-full">
        <h2 className="text-xl font-bold">Add Causes:</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 w-full">
          {categories.map(category => (
            <div key={category} className="flex items-center mb-2 w-full">
              <button
                onClick={() => removeCategory(category)}
                className="p-2 mr-2 bg-red-500 text-white rounded hover:bg-red-600 active:bg-red-700 transition-colors"
                aria-label={`Remove category ${category}`}
              >
                <XCircle size={16} />
              </button>
              <span className="font-bold mr-2">{category}:</span>
              <input
                type="text"
                placeholder={`Add cause for ${category}`}
                className="p-2 border rounded mr-2 flex-grow"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    addCause(category, e.target.value);
                    e.target.value = '';
                  }
                }}
                aria-label={`Add cause for ${category}`}
              />
              <button
                onClick={(e) => {
                  const input = e.target.previousElementSibling;
                  addCause(category, input.value);
                  input.value = '';
                }}
                className="p-2 bg-green-500 text-white rounded hover:bg-green-600 active:bg-green-700 transition-colors"
                aria-label={`Add cause to ${category}`}
              >
                Add
              </button>
            </div>
          ))}
        </div>
      </div>
  
      {/* Render the SVG Diagram */}
      <div className="mb-4 overflow-x-auto w-full">
        <h2 className="text-xl font-bold">Fishbone Diagram</h2>
        {renderFishboneDiagram()}
      </div>
  
      {/* Causes Summary Section */}
      <div className="mt-4 w-full">
        <h2 className="text-xl font-bold">Causes Summary:</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 w-full">
          {categories.map(category => (
            <div key={category} className="mb-4">
              <h3 className="font-bold">{category}</h3>
              <ul className="list-disc pl-5">
                {causes[category] && causes[category].map((cause, index) => (
                  <li key={index} className="flex items-center justify-between mb-2">
                    {editingCause && editingCause.category === category && editingCause.index === index ? (
                      <input
                        type="text"
                        value={editValue}
                        onChange={(e) => setEditValue(e.target.value)}
                        className="p-1 border rounded mr-2 flex-grow"
                        aria-label={`Edit cause for ${category}`}
                      />
                    ) : (
                      <span>{cause}</span>
                    )}
                    <div className="flex items-center space-x-2">
                      <select
                        value={getCauseClassification(cause)}
                        onChange={(e) => classifyCause(cause, e.target.value)}
                        className="p-1 border rounded"
                        aria-label={`Classify cause for ${category}`}
                      >
                        <option value="">Unclassified</option>
                        <option value="root">Root</option>
                        <option value="contributing">Contributing</option>
                        <option value="possible">Possible</option>
                      </select>
                      {editingCause && editingCause.category === category && editingCause.index === index ? (
                        <>
                          <button onClick={saveEdit} className="p-1 text-green-500 hover:text-green-700" aria-label="Save edit">
                            <Save size={16} />
                          </button>
                          <button onClick={cancelEdit} className="p-1 text-gray-500 hover:text-gray-700" aria-label="Cancel edit">
                            <XCircle size={16} />
                          </button>
                        </>
                      ) : (
                        <button
                          onClick={() => startEditing(category, index)}
                          className="p-1 text-blue-500 hover:text-blue-700"
                          aria-label={`Edit cause for ${category}`}
                        >
                          <Edit2 size={16} />
                        </button>
                      )}
                      <button
                        onClick={() => removeCause(category, index)}
                        className="p-1 text-red-500 hover:text-red-700"
                        aria-label={`Remove cause for ${category}`}
                      >
                        <XCircle size={16} />
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
  
      <div className="mt-4 w-full">
        <h2 className="text-xl font-bold">Conclusion:</h2>
        <textarea
          value={conclusion}
          onChange={(e) => setConclusion(e.target.value)}
          placeholder="Enter your conclusion based on the analysis"
          className="w-full p-2 border rounded h-32"
          aria-label="Conclusion"
        />
      </div>
  
      <div className="mt-4 flex space-x-2">
        <button
          onClick={refreshDiagram}
          className="p-2 bg-gray-500 text-white rounded hover:bg-gray-600 active:bg-gray-700 transition-colors flex items-center"
          aria-label="Refresh diagram"
        >
          <RefreshCw className="mr-2" size={16} />
          Refresh
        </button>
        <button
          onClick={saveDiagram}
          className="p-2 bg-green-500 text-white rounded hover:bg-green-600 active:bg-green-700 transition-colors flex items-center"
          aria-label="Save diagram"
        >
          <Save className="mr-2" size={16} />
          Save
        </button>
      </div>
  
      {savedContent && (
        <div className="mt-4 w-full">
          <h2 className="text-xl font-bold">Saved Content:</h2>
          <p className="mb-2">Please copy the following text manually:</p>
          <pre className="whitespace-pre-wrap bg-gray-100 p-4 rounded border">
            {savedContent}
          </pre>
        </div>
      )}
    </div>
  );
  
};

export default FishboneDiagramTool;
