import React, { useState } from 'react';
import { PlusCircle, Save, RefreshCw } from 'lucide-react';

const FiveWhyAnalysisTool = () => {
  const [teamName, setTeamName] = useState('');
  const [problem, setProblem] = useState('');
  const [whys, setWhys] = useState([
    { because: '', isRoot: false, contributing: '', possible: '' }
  ]);
  const [actions, setActions] = useState([]);

  const addWhy = () => {
    if (whys.length < 5) {
      setWhys([...whys, { because: '', isRoot: false, contributing: '', possible: '' }]);
    }
  };

  const updateWhy = (index, field, value) => {
    const updatedWhys = whys.map((why, i) => 
      i === index ? { ...why, [field]: value } : why
    );
    setWhys(updatedWhys);
  };

  const addAction = () => {
    setActions([...actions, { action: '', owner: '', status: 'Not Started' }]);
  };

  const updateAction = (index, field, value) => {
    const updatedActions = actions.map((action, i) => 
      i === index ? { ...action, [field]: value } : action
    );
    setActions(updatedActions);
  };

  const resetAnalysis = () => {
    setTeamName('');
    setProblem('');
    setWhys([{ because: '', isRoot: false, contributing: '', possible: '' }]);
    setActions([]);
  };

  return (
    <div className="p-4 max-w-full mx-auto">
      <h1 className="text-3xl font-bold text-center text-white bg-blue-900 py-4 mb-4">The Five Whys</h1>
      
      <div className="bg-yellow-100 p-4 mb-4">
        <input
          type="text"
          placeholder="Enter team name here"
          value={teamName}
          onChange={(e) => setTeamName(e.target.value)}
          className="w-full p-2 mb-2"
        />
        <input
          type="text"
          placeholder="Enter problem or opportunity here"
          value={problem}
          onChange={(e) => setProblem(e.target.value)}
          className="w-full p-2"
        />
      </div>

      <div className="bg-teal-100 p-4 mb-4 overflow-x-auto">
        <table className="w-full min-w-max">
          <thead>
            <tr className="font-bold">
              <th className="p-2 text-left whitespace-nowrap">Step</th>
              <th className="p-2 text-left">This happened because:</th>
              <th className="p-2 text-left whitespace-nowrap">Is this the root cause?</th>
              <th className="p-2 text-left">Contributing Causes</th>
              <th className="p-2 text-left">Possible Causes</th>
            </tr>
          </thead>
          <tbody>
            {whys.map((why, index) => (
              <tr key={index}>
                <td className="p-2 whitespace-nowrap">Why {index + 1}</td>
                <td className="p-2">
                  <input
                    type="text"
                    value={why.because}
                    onChange={(e) => updateWhy(index, 'because', e.target.value)}
                    className="w-full p-2 border"
                  />
                </td>
                <td className="p-2">
                  <select
                    value={why.isRoot ? 'Yes' : 'No'}
                    onChange={(e) => updateWhy(index, 'isRoot', e.target.value === 'Yes')}
                    className="w-full p-2 border"
                  >
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select>
                </td>
                <td className="p-2">
                  <input
                    type="text"
                    value={why.contributing}
                    onChange={(e) => updateWhy(index, 'contributing', e.target.value)}
                    className="w-full p-2 border"
                  />
                </td>
                <td className="p-2">
                  <input
                    type="text"
                    value={why.possible}
                    onChange={(e) => updateWhy(index, 'possible', e.target.value)}
                    className="w-full p-2 border"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {whys.length < 5 && (
          <button onClick={addWhy} className="mt-2 p-2 bg-blue-500 text-white rounded hover:bg-blue-600">
            <PlusCircle className="inline mr-2" size={16} />
            Add Why
          </button>
        )}
      </div>

      <div className="bg-teal-100 p-4 mb-4 overflow-x-auto">
        <h2 className="text-xl font-bold mb-2">Action Items</h2>
        <table className="w-full min-w-max">
          <thead>
            <tr className="font-bold">
              <th className="p-2 text-left whitespace-nowrap">Number</th>
              <th className="p-2 text-left">Action Item</th>
              <th className="p-2 text-left">Owner</th>
              <th className="p-2 text-left">Status</th>
            </tr>
          </thead>
          <tbody>
            {actions.map((action, index) => (
              <tr key={index}>
                <td className="p-2 whitespace-nowrap">{index + 1}</td>
                <td className="p-2">
                  <input
                    type="text"
                    value={action.action}
                    onChange={(e) => updateAction(index, 'action', e.target.value)}
                    className="w-full p-2 border"
                  />
                </td>
                <td className="p-2">
                  <input
                    type="text"
                    value={action.owner}
                    onChange={(e) => updateAction(index, 'owner', e.target.value)}
                    className="w-full p-2 border"
                  />
                </td>
                <td className="p-2">
                  <select
                    value={action.status}
                    onChange={(e) => updateAction(index, 'status', e.target.value)}
                    className="w-full p-2 border"
                  >
                    <option>Not Started</option>
                    <option>In Progress</option>
                    <option>Complete</option>
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button onClick={addAction} className="mt-2 p-2 bg-blue-500 text-white rounded hover:bg-blue-600">
          <PlusCircle className="inline mr-2" size={16} />
          Add Action Item
        </button>
      </div>

      <div className="flex justify-end space-x-2">
        <button onClick={resetAnalysis} className="p-2 bg-gray-500 text-white rounded hover:bg-gray-600">
          <RefreshCw className="inline mr-2" size={16} />
          Reset
        </button>
        <button className="p-2 bg-green-500 text-white rounded hover:bg-green-600">
          <Save className="inline mr-2" size={16} />
          Save
        </button>
      </div>
    </div>
  );
};

export default FiveWhyAnalysisTool;