import React, { useState } from 'react';

const ProjectInitialization = ({ onProjectInit }) => {
  const [projectName, setProjectName] = useState('');
  const [teamMembers, setTeamMembers] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const projectId = `RCA-${new Date().toISOString().split('T')[0]}-${Math.random().toString(36).substr(2, 5)}`;
    onProjectInit({
      projectName,
      teamMembers: teamMembers.split(',').map(member => member.trim()),
      projectId
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <h2 className="text-xl font-bold">Initialize Root Cause Analysis Project</h2>
      <div>
        <label className="block">Project Name:</label>
        <input
          type="text"
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
          className="w-full p-2 border rounded"
          required
        />
      </div>
      <div>
        <label className="block">Team Members (comma-separated):</label>
        <input
          type="text"
          value={teamMembers}
          onChange={(e) => setTeamMembers(e.target.value)}
          className="w-full p-2 border rounded"
          required
        />
      </div>
      <button type="submit" className="p-2 bg-blue-500 text-white rounded">
        Initialize Project
      </button>
    </form>
  );
};

export default ProjectInitialization;