import React from 'react';

const ReportGenerator = (props) => {
  const generateReport = () => {
    const { project, problemStatement, fiveWhysData, rootCauses, correctiveActions } = props;
    let content = `
Root Cause Analysis Report

Project Information:
Project Name: ${project?.projectName || 'N/A'}
Project ID: ${project?.projectId || 'N/A'}
Team Members: ${project?.teamMembers?.join(', ') || 'N/A'}

Problem Statement:
${problemStatement?.full || 'N/A'}

5 Whys Analysis:
${fiveWhysData?.map((why, index) => `Why ${index + 1}: ${why}`).join('\n') || 'N/A'}

Root Causes:
${rootCauses?.join('\n') || 'N/A'}

Corrective Actions:
${correctiveActions?.map(action => `
Action: ${action.description}
Responsible: ${action.responsible}
Due Date: ${action.dueDate}
Status: ${action.status}
`).join('\n') || 'N/A'}
    `.trim();

    return content;
  };

  const handleCopyReport = () => {
    const report = generateReport();
    navigator.clipboard.writeText(report).then(() => {
      alert('Report copied to clipboard!');
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };

  return (
    <div className="space-y-4">
      <h2 className="text-2xl font-bold text-blue-600 mb-4">Report Generator</h2>
      <button 
        onClick={handleCopyReport}
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
      >
        Copy Report to Clipboard
      </button>
      <pre className="whitespace-pre-wrap bg-gray-100 p-4 rounded border">
        {generateReport()}
      </pre>
    </div>
  );
};

export default ReportGenerator;

