import React, { useState } from 'react';
import ProjectInitialization from './ProjectInitialization';
import ProblemStatementBuilder from './ProblemStatementBuilder';
import ChartGeneratorTool from './ChartGeneratorTool';
import FiveWhyAnalysisTool from './FiveWhyAnalysisTool';
import FishboneDiagramTool from './FishboneDiagramTool';
import RootCauseClassification from './RootCauseClassification';
import GuidelinesForWritingCAs from './GuidelinesForWritingCAs';
import CorrectiveActionRecord from './CorrectiveActionRecord';
import Glossary from './Glossary';
import ReportGenerator from './ReportGenerator';

const Tab = ({ children, isActive, onClick }) => (
  <button
    className={`px-4 py-2 ${isActive ? 'bg-blue-500 text-white' : 'bg-gray-200'} rounded-t-lg`}
    onClick={onClick}
  >
    {children}
  </button>
);

const RootCauseAnalysisPro = () => {
  const [activeTab, setActiveTab] = useState('project-init');
  const [activeSubtab, setActiveSubtab] = useState('overview');
  const [project, setProject] = useState(null);
  const [problemStatement, setProblemStatement] = useState({ short: '', full: '' });
  const [fiveWhysData, setFiveWhysData] = useState([]);
  const [fishboneData, setFishboneData] = useState({});
  const [paretoData, setParetoData] = useState([]);
  const [rootCauses, setRootCauses] = useState([]);
  const [correctiveActions, setCorrectiveActions] = useState([]);

  const mainTabs = [
    { id: 'project-init', label: 'Project Initialization' },
    { id: 'problem-definition', label: 'Problem Definition' },
    { id: 'root-cause-identification', label: 'Root Cause Identification' },
    { id: 'corrective-action-plan', label: 'Corrective Action Plan' },
    { id: 'corrective-action-implementation', label: 'Corrective Action Implementation' },
    { id: 'validation-of-effectiveness', label: 'Validation of Effectiveness' },
  ];

  const utilityTabs = [
    { id: 'glossary', label: 'Glossary' },
    { id: 'report', label: 'Generate Report' }
  ];

  const rootCauseSubtabs = [
    { id: 'overview', label: 'Overview' },
    { id: 'five-whys', label: '5 Whys' },
    { id: 'fishbone', label: 'Fishbone Diagram' },
    { id: 'pareto', label: 'Pareto Chart' },
    { id: 'root-cause-classification', label: 'Root Cause Classification' }
  ];

  const correctiveActionSubtabs = [
    { id: 'guidelines', label: 'Guidelines for Writing CAs' },
    { id: 'car', label: 'Corrective Action Record (CAR)' }
  ];

  const handleProjectInit = (projectData) => {
    setProject(projectData);
    setActiveTab('problem-definition');
  };

  const handleProblemStatementChange = (shortDescription, fullStatement) => {
    setProblemStatement({ short: shortDescription, full: fullStatement });
  };

  const handleFiveWhysUpdate = (data) => {
    setFiveWhysData(data);
  };

  const handleFishboneUpdate = (data) => {
    setFishboneData(data);
  };

  const handleParetoUpdate = (data) => {
    setParetoData(data);
  };

  const handleRootCausesUpdate = (causes) => {
    setRootCauses(causes);
  };

  const handleCorrectiveActionsUpdate = (actions) => {
    setCorrectiveActions(actions);
  };
  
  const renderContent = () => {
    switch (activeTab) {
      case 'project-init':
        return <ProjectInitialization onProjectInit={handleProjectInit} />;
      case 'problem-definition':
        return (
          <div>
            <h1 className="text-2xl font-bold text-blue-600 mb-4">Problem Definition</h1>
            <ProblemStatementBuilder onProblemStatementChange={handleProblemStatementChange} />
            <div className="mt-8">
              <ChartGeneratorTool onParetoUpdate={handleParetoUpdate} data={paretoData} />
            </div>
          </div>
        );
      case 'root-cause-identification':
        return (
          <div>
            <h1 className="text-2xl font-bold text-blue-600 mb-4">Root Cause Identification</h1>
            <p className="mb-4">Problem: {problemStatement.short}</p>
            <div className="flex mb-4">
              {rootCauseSubtabs.map((subtab) => (
                <Tab
                  key={subtab.id}
                  isActive={activeSubtab === subtab.id}
                  onClick={() => setActiveSubtab(subtab.id)}
                >
                  {subtab.label}
                </Tab>
              ))}
            </div>
            {activeSubtab === 'overview' && <div>Overview of Root Cause Identification tools</div>}
            {activeSubtab === 'five-whys' && <FiveWhyAnalysisTool onUpdate={handleFiveWhysUpdate} data={fiveWhysData} />}
            {activeSubtab === 'fishbone' && <FishboneDiagramTool onUpdate={handleFishboneUpdate} data={fishboneData} />}
            {activeSubtab === 'pareto' && <ChartGeneratorTool onParetoUpdate={handleParetoUpdate} data={paretoData} />}
            {activeSubtab === 'root-cause-classification' && <RootCauseClassification onUpdate={handleRootCausesUpdate} causes={rootCauses} />}
          </div>
        );
      case 'corrective-action-plan':
        return (
          <div>
            <h1 className="text-2xl font-bold text-blue-600 mb-4">Corrective Action Plan</h1>
            <div className="flex mb-4">
              {correctiveActionSubtabs.map((subtab) => (
                <Tab
                  key={subtab.id}
                  isActive={activeSubtab === subtab.id}
                  onClick={() => setActiveSubtab(subtab.id)}
                >
                  {subtab.label}
                </Tab>
              ))}
            </div>
            {activeSubtab === 'guidelines' && <GuidelinesForWritingCAs />}
            {activeSubtab === 'car' && <CorrectiveActionRecord onUpdate={handleCorrectiveActionsUpdate} actions={correctiveActions} />}
          </div>
        );
      case 'corrective-action-implementation':
        return <div>Corrective Action Implementation Content</div>;
      case 'validation-of-effectiveness':
        return <div>Validation of Effectiveness Content</div>;
      case 'glossary':
        return <Glossary />;
      case 'report':
        return <ReportGenerator
          project={project}
          problemStatement={problemStatement}
          fiveWhysData={fiveWhysData}
          fishboneData={fishboneData}
          paretoData={paretoData}
          rootCauses={rootCauses}
          correctiveActions={correctiveActions}
        />;
      default:
        return null;
    }
  };

  return (
    <div className="container mx-auto px-4 relative min-h-screen pb-16">
      <header className="bg-white shadow-md py-4 mb-6">
        <div className="text-2xl font-bold text-blue-600">Root Cause Analysis Pro</div>
        {project && (
          <div className="text-sm text-gray-600 mt-2">
            Project: {project.projectName} | ID: {project.projectId} | Team: {project.teamMembers.join(', ')}
          </div>
        )}
        <nav className="mt-4">
          <div className="flex">
            {mainTabs.map((tab) => (
              <Tab
                key={tab.id}
                isActive={activeTab === tab.id}
                onClick={() => setActiveTab(tab.id)}
              >
                {tab.label}
              </Tab>
            ))}
          </div>
        </nav>
      </header>
      
      <main className="bg-white border border-gray-200 p-6 min-h-[300px]">
        {renderContent()}
      </main>

      <div className="fixed bottom-4 right-4 flex space-x-2">
        {utilityTabs.map((tab) => (
          <button
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            {tab.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default RootCauseAnalysisPro;