import React, { useState } from 'react';

const Glossary = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const glossaryTerms = [
    { term: 'Root Cause', definition: 'The fundamental reason for the occurrence of a problem.' },
    { term: 'Corrective Action', definition: 'An action implemented to address the root cause of a detected problem or other undesirable situation.' },
    { term: 'Fishbone Diagram', definition: 'Also known as Ishikawa diagram, a visualization tool for categorizing the potential causes of a problem to identify its root causes.' },
    { term: '5 Whys', definition: 'An iterative interrogative technique used to explore the cause-and-effect relationships underlying a particular problem.' },
    { term: 'Pareto Chart', definition: 'A type of chart that contains both bars and a line graph, where individual values are represented in descending order by bars, and the line represents the cumulative total.' },
    // Add more terms as needed
  ];

  const filteredTerms = glossaryTerms.filter(item =>
    item.term.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.definition.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="space-y-4">
      <h2 className="text-2xl font-bold text-blue-600 mb-4">Glossary</h2>
      <input
        type="text"
        placeholder="Search terms..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="w-full p-2 border rounded"
      />
      <div className="space-y-4">
        {filteredTerms.map((item, index) => (
          <div key={index} className="border-b pb-2">
            <h3 className="font-bold">{item.term}</h3>
            <p>{item.definition}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Glossary;