import React, { useState, useEffect, useCallback } from 'react';
import { ComposedChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell, ReferenceLine } from 'recharts';
import { PlusCircle, Trash2 } from 'lucide-react';

const ChartGeneratorTool = () => {
  const [chartType, setChartType] = useState('pareto');
  const [data, setData] = useState([{ label: '', value: '' }]);
  const [processedData, setProcessedData] = useState([]);
  const [chartTitle, setChartTitle] = useState('Pareto Chart');
  const [error, setError] = useState(null);

  const addRow = () => {
    setData([...data, { label: '', value: '' }]);
  };

  const removeRow = (index) => {
    const newData = data.filter((_, i) => i !== index);
    setData(newData);
  };

  const updateData = (index, field, value) => {
    const newData = [...data];
    newData[index][field] = value;
    setData(newData);
  };

  const processData = useCallback(() => {
    try {
      const validData = data.filter(item => item.label && item.value && !isNaN(item.value));
      validData.sort((a, b) => parseFloat(b.value) - parseFloat(a.value));
      let totalSum = validData.reduce((sum, item) => sum + parseFloat(item.value), 0);
      let cumulativePercentage = 0;
      const processedData = validData.map((item, index) => {
        cumulativePercentage += (parseFloat(item.value) / totalSum) * 100;
        return {
          label: item.label,
          value: parseFloat(item.value),
          percentage: (parseFloat(item.value) / totalSum) * 100,
          cumulativePercentage: cumulativePercentage,
          cumulativePosition: index / (validData.length - 1)
        };
      });
      setProcessedData(processedData);
      setError(null);
    } catch (err) {
      console.error("Error processing data:", err);
      setError("An error occurred while processing the data. Please check your input and try again.");
    }
  }, [data]);

  useEffect(() => {
    processData();
  }, [processData]);

  const renderChart = () => {
    if (processedData.length === 0) return null;
    const colors = ['#8dd3c7', '#ffffb3', '#bebada', '#fb8072', '#80b1d3', '#fdb462', '#b3de69', '#fccde5', '#d9d9d9'];
    
    return (
      <ResponsiveContainer width="100%" height={500}>
        <ComposedChart 
          data={processedData}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="label" />
          <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
          <YAxis 
            yAxisId="right"
            orientation="right"
            stroke="#82ca9d"
            tickFormatter={(value) => `${value.toFixed(0)}%`}
           domain={[0, 100]}
          />
          <Tooltip 
            formatter={(value, name) => {
              if (name === "Cumulative %") {
                return [`${value.toFixed(2)}%`, name];
              }
              return [value, name];
            }}
          />
          <Legend />
          <Bar yAxisId="left" dataKey="value" name="Value" fill="#8884d8">
            {processedData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
            ))}
          </Bar>
          {chartType === 'pareto' && (
            <>
              <Line 
                yAxisId="right"
                type="monotone"
                dataKey="cumulativePercentage"
                stroke="#ff7300"
                name="Cumulative %"
                dot={{ stroke: '#ff7300', strokeWidth: 2, r: 4 }}
               strokeWidth={2}
              />
              <ReferenceLine y={80} yAxisId="right" stroke="#808080" strokeDasharray="3 3" />
            </>
          )}
        </ComposedChart>
      </ResponsiveContainer>
    );
  };

  return (
    <div className="p-4 max-w-6xl mx-auto">
      <h1 className="text-3xl font-bold mb-4">Chart Generator Tool</h1>
      
      <div className="mb-4">
        <label className="block mb-2">Chart Type:</label>
        <select 
          value={chartType}
          onChange={(e) => setChartType(e.target.value)}
          className="w-full p-2 border rounded"
        >
          <option value="pareto">Pareto Chart</option>
          <option value="histogram">Histogram</option>
        </select>
      </div>
      <div className="mb-4">
        <label className="block mb-2">Chart Title:</label>
        <input
          type="text"
          value={chartTitle}
          onChange={(e) => setChartTitle(e.target.value)}
          className="w-full p-2 border rounded"
        />
      </div>
      <div className="mb-4">
        <h2 className="text-2xl font-bold mb-2">Enter Data</h2>
        <table className="w-full border-collapse">
          <thead>
            <tr>
              <th className="border p-2">Label</th>
              <th className="border p-2">Value</th>
              <th className="border p-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td className="border p-2">
                  <input
                    type="text"
                    value={item.label}
                    onChange={(e) => updateData(index, 'label', e.target.value)}
                    className="w-full p-1"
                  />
                </td>
                <td className="border p-2">
                  <input
                    type="number"
                    value={item.value}
                    onChange={(e) => updateData(index, 'value', e.target.value)}
                    className="w-full p-1"
                  />
                </td>
                <td className="border p-2">
                  <button onClick={() => removeRow(index)} className="text-red-500">
                    <Trash2 size={16} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button onClick={addRow} className="mt-2 p-2 bg-blue-500 text-white rounded hover:bg-blue-600">
          <PlusCircle className="inline mr-2" size={16} />
          Add Row
        </button>
      </div>
      {error && (
        <div className="mb-4 p-4 bg-red-100 text-red-700 rounded">
          {error}
        </div>
      )}
      {processedData.length > 0 && !error && (
        <div className="mb-4">
          <h2 className="text-2xl font-bold mb-2">{chartTitle}</h2>
          {renderChart()}
        </div>
      )}
    </div>
  );
};

export default ChartGeneratorTool;