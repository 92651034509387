import React, { useState } from 'react';

const GuidelinesForWritingCAs = () => {
  const [checklist, setChecklist] = useState({
    accuracy: false,
    measurable: false,
    accountability: false,
    reasonable: false,
    timely: false
  });

  const guidelines = {
    accuracy: [
      "Write in complete sentences",
      "Begin each action with an action verb",
      "Avoid abbreviations and acronyms",
      "Be precise when defining the requirement and deficiency",
      "State what you are going to do",
      "Have one action per corrective action"
    ],
    measurable: [
      "Set actions that may be measurable",
      "Consider how the completion of the action can be documented",
      "Avoid use of terms such as 'all', 'ongoing', 'continue', or 'improve'",
      "Avoid impossible actions to measure",
      "Avoid situations in which the completion of an action is open interpretation"
    ],
    accountability: [
      "Responsibility must be clearly understood and accepted",
      "Resource allocations to support implementation must be clearly established",
      "Ensure necessary qualifications and/or training needs to perform action(s) are identified and understood"
    ],
    reasonable: [
      "Ensure that the corrective action is practical",
      "Discuss the proposed corrective action with those who will implement it"
    ],
    timely: [
      "Avoid establishing target dates which are too optimistic or too far in the future",
      "Evaluate carefully the implementation time frame",
      "Consider funds availability, human resource availability, linked activities and actions, other commitments, and documentation requirements"
    ]
  };

  const handleCheckboxChange = (category) => {
    setChecklist(prev => ({ ...prev, [category]: !prev[category] }));
  };

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold">Guidelines for Writing Corrective Actions</h2>
      {Object.entries(guidelines).map(([category, items]) => (
        <div key={category} className="space-y-2">
          <div className="flex items-center">
            <input
              type="checkbox"
              id={category}
              checked={checklist[category]}
              onChange={() => handleCheckboxChange(category)}
              className="mr-2"
            />
            <label htmlFor={category} className="font-bold capitalize">{category}</label>
          </div>
          <ul className="list-disc pl-6">
            {items.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default GuidelinesForWritingCAs;