import React, { useState, useEffect } from 'react';
import rootCauseData from '../data/RootCauseClass';

const RootCauseClassification = () => {
  const [investigationTitles, setInvestigationTitles] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [subcategories, setSubcategories] = useState([]);
  const [selectedRootCauses, setSelectedRootCauses] = useState([]);

  useEffect(() => {
    const uniqueTitles = [...new Set(rootCauseData.query.map(item => item['Investigation Title']))];
    setInvestigationTitles(uniqueTitles);
  }, []);

  useEffect(() => {
    if (selectedTitle) {
      const filteredCategories = [...new Set(rootCauseData.query
        .filter(item => item['Investigation Title'] === selectedTitle)
        .map(item => item.Category))];
      setCategories(filteredCategories);
      setSelectedCategory('');
    } else {
      setCategories([]);
    }
  }, [selectedTitle]);

  useEffect(() => {
    if (selectedCategory) {
      const filteredSubcategories = rootCauseData.query.filter(
        item => item['Investigation Title'] === selectedTitle && item.Category === selectedCategory
      );
      setSubcategories(filteredSubcategories);
    } else {
      setSubcategories([]);
    }
  }, [selectedTitle, selectedCategory]);

  const handleTitleChange = (e) => {
    setSelectedTitle(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleSubcategorySelect = (subcategory) => {
    if (!selectedRootCauses.some(item => item.Code === subcategory.Code)) {
      setSelectedRootCauses([...selectedRootCauses, subcategory]);
    }
  };

  const handleRemoveRootCause = (code) => {
    setSelectedRootCauses(selectedRootCauses.filter(item => item.Code !== code));
  };

  return (
    <div className="space-y-4">
      <h2 className="text-xl font-bold">Root Cause Classification</h2>
      
      <div>
        <label className="block mb-2">Select Investigation Title:</label>
        <select 
          value={selectedTitle} 
          onChange={handleTitleChange}
          className="w-full p-2 border rounded"
        >
          <option value="">Select an investigation title</option>
          {investigationTitles.map(title => (
            <option key={title} value={title}>{title}</option>
          ))}
        </select>
      </div>

      {selectedTitle && (
        <div>
          <label className="block mb-2">Select Category:</label>
          <select 
            value={selectedCategory} 
            onChange={handleCategoryChange}
            className="w-full p-2 border rounded"
          >
            <option value="">Select a category</option>
            {categories.map(category => (
              <option key={category} value={category}>{category}</option>
            ))}
          </select>
        </div>
      )}

      {selectedCategory && (
        <div>
          <label className="block mb-2">Select Sub-category:</label>
          <ul className="max-h-60 overflow-y-auto border rounded p-2">
            {subcategories.map(subcategory => (
              <li 
                key={subcategory.Code} 
                onClick={() => handleSubcategorySelect(subcategory)}
                className="cursor-pointer hover:bg-gray-100 p-1"
              >
                {subcategory.Code} - {subcategory['Sub-category']}
              </li>
            ))}
          </ul>
        </div>
      )}

      {selectedRootCauses.length > 0 && (
        <div>
          <h3 className="font-bold mb-2">Selected Root Causes:</h3>
          <ul className="space-y-2">
            {selectedRootCauses.map(cause => (
              <li key={cause.Code} className="flex justify-between items-center bg-gray-100 p-2 rounded">
                <span>{cause.Code} - {cause['Sub-category']}</span>
                <button 
                  onClick={() => handleRemoveRootCause(cause.Code)}
                  className="text-red-500 hover:text-red-700"
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default RootCauseClassification;