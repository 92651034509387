import React, { useState, useEffect, useCallback } from 'react';

const ProblemStatementBuilder = ({ onProblemStatementChange }) => {
  const [what, setWhat] = useState('');
  const [where, setWhere] = useState('');
  const [when, setWhen] = useState('');
  const [who, setWho] = useState('');
  const [how, setHow] = useState('');
  const [why, setWhy] = useState('');
  const [editableStatement, setEditableStatement] = useState('');
  const [shortDescription, setShortDescription] = useState('');

  const generateStatement = useCallback(() => {
    const statement = `The problem is ${what} occurring at ${where}. It was first noticed on ${when} and affects ${who}. The issue manifests by ${how}. This is significant because ${why}.`;
    setEditableStatement(statement);
  }, [what, where, when, who, how, why]);

  useEffect(() => {
    generateStatement();
  }, [generateStatement]);

  useEffect(() => {
    if (typeof onProblemStatementChange === 'function') {
      onProblemStatementChange(shortDescription, editableStatement);
    }
  }, [shortDescription, editableStatement, onProblemStatementChange]);

  return (
    <div className="space-y-4">
      <h2 className="text-xl font-bold">Problem Statement Builder</h2>
      <div>
        <label className="block">What is the issue?</label>
        <input type="text" value={what} onChange={(e) => setWhat(e.target.value)} className="w-full p-2 border rounded" />
      </div>
      <div>
        <label className="block">Where does it occur?</label>
        <input type="text" value={where} onChange={(e) => setWhere(e.target.value)} className="w-full p-2 border rounded" />
      </div>
      <div>
        <label className="block">When did it start/occur?</label>
        <input type="text" value={when} onChange={(e) => setWhen(e.target.value)} className="w-full p-2 border rounded" />
      </div>
      <div>
        <label className="block">Who is affected?</label>
        <input type="text" value={who} onChange={(e) => setWho(e.target.value)} className="w-full p-2 border rounded" />
      </div>
      <div>
        <label className="block">How does the issue manifest?</label>
        <input type="text" value={how} onChange={(e) => setHow(e.target.value)} className="w-full p-2 border rounded" />
      </div>
      <div>
        <label className="block">Why is this significant?</label>
        <input type="text" value={why} onChange={(e) => setWhy(e.target.value)} className="w-full p-2 border rounded" />
      </div>
      <div className="p-4 bg-gray-100 rounded">
        <h3 className="font-bold">Edit Generated Problem Statement:</h3>
        <textarea
          value={editableStatement}
          onChange={(e) => setEditableStatement(e.target.value)}
          className="w-full p-2 border rounded"
          rows="4"
        />
      </div>
      <div>
        <label className="block">Short Problem Description:</label>
        <input
          type="text"
          value={shortDescription}
          onChange={(e) => setShortDescription(e.target.value)}
          className="w-full p-2 border rounded"
          placeholder="Enter a concise description of the problem"
        />
      </div>
    </div>
  );
};

export default ProblemStatementBuilder;